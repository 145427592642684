import $ from "jquery";
import popper from "popper.js";
import bootstrap from "bootstrap";

$(function () {
  const $body = $("body");
  const activeEnvironment = $body.data("active-environment");

  if (activeEnvironment !== "prod") {
    let envText = "";
    for (let i = 0; i < 200; i++) {
      envText = envText + activeEnvironment + " ";
    }
    $body.append("<div id='webber-background-text'>" + envText + "</div>");
    window.document.title = "(" + activeEnvironment + ") " + window.document.title
  }
});

export default (window.$ = window.jQuery = $);